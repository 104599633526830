import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  supportInfo: `/support/index`, // 语言
  singlePage: `/support/service_policy_detail`, // 隐私政策单页
  videoMenu: `/video/menu_list`,
  videoList: `/video/list`
}
// 视频分类：
// get请求：http://10.1.51.64:3100/frontInterface/video/menu_list

// 视频列表：
// get请求：http://10.1.51.64:3100/frontInterface/video/list
// 参数：menu_id分类menu_id（必须），page页码、limit每页条数
// 语言
export const getSupportInfo = () => fetch(`${apiUrl.supportInfo}/${lang}/${site}`)
// 隐私政策单页
export const getSinglePage = (id) => fetch(`${apiUrl.singlePage}/${lang}/${site}` + `?id=${id}`)
// 视频分类
export const getVideoMenu = () => fetch(`${apiUrl.videoMenu}/${lang}/${site}`)
// 视频列表
export const getVideoList= (params) => fetch(`${apiUrl.videoList}/${lang}/${site}`,params)

