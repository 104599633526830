<template>
  <div class="videos">
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="videosData" />
    <div class="videos_detail container">
      <ul class="videos_menu">
        <li
          v-for="(item, index) in menuList"
          :key="index"
          class="videos_menu_item"
          :class="{ active: tabShowIndex === index }"
          @click="changHandle(item, index)"
        >
          <span>{{ item.menu_name }}</span>
        </li>
      </ul>
      <div class="videos_list">
        <div v-for="(item, index) in videosList" :key="index" class="videos_list_item">
          <div class="videos_video">
            <video
              :src="item.video"
              x5-video-player-type="h5"
              webkit-playsinline="true"
              playsinline="true"
              class="videos_player"
              :poster="item.image"
            ></video>
            <div class="video-icon-box" @click="videoHandler(index)">
              <i class="iconfont icon-a-playCircle1x"></i>
            </div>
          </div>
          <div class="videos_content" @click="handleLink(item)">
            <p class="videos_text">{{ item.title }}</p>
            <span v-if="item.product_name" class="title">{{ LP.lang_related_products }}</span>
            <span class="model" v-html="item.product_name"></span>
          </div>
        </div>
      </div>
      <div v-if="videosList.length">
        <el-pagination
          background
          layout=" prev, pager, next"
          :total="count"
          :page-size="limit"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { getVideoMenu, getVideoList } from '@/api/videos'
import { mapState } from 'vuex'
export default {
  components: {
    PublicBreadCrumb,
    PublicTitle,
  },
  data() {
    return {
      videosData: {
        title: '',
      },
      menuList: [],
      tabShowIndex: 0,
      videosList: [],
      currentPage: 1,
      count: 0,
      limit: 9,
      menu_id: '',
    }
  },
  computed: {
    ...mapState({
      navigator: (state) => state.common.navigator,
      whiteHeader: (state) => state.header.whiteHeader,
    }),
  },
  async created() {
    // 头部导航颜色
    this.$store.commit('header/SET_WHITE_HEADER', true)
    // 面包屑
    await this.$store.dispatch('common/getNavigator', { type: 'video_list', id: 1, level: 1 })
    await getVideoMenu().then((res) => {
      this.menuList = res.data.menu_list
      this.videosData.title = res.data.nav_name
      this.$store.commit('common/SET_MATE', res.data.seo)
      this.menu_id = res.data.menu_list[0].menu_id
      this.getVideoLists(res.data.menu_list[0].menu_id, this.currentPage, this.limit)
    })
  },
  methods: {
    getVideoLists(menu_id, page, limit) {
      const params = { menu_id, page, limit }
      // 参数：menu_id分类menu_id（必须），page页码、limit每页条数
      getVideoList(params).then((res) => {
        this.count = res.data.count
        this.videosList = res.data.list
      })
    },
    // 切换数据
    changHandle(item, index) {
      this.tabShowIndex = index
      this.menu_id = item.menu_id
      this.currentPage = 1
      this.limit = 9
      this.getVideoLists(item.menu_id, 1, this.limit)
    },
    // 视频播放
    videoHandler(index) {
      document.getElementsByClassName('videos_player')[index].controls = true
      document.getElementsByClassName('videos_player')[index].play()
      document.getElementsByClassName('videos_player')[index].poster = ''
      document.getElementsByClassName('video-icon-box')[index].style.display = 'none'
    },
    handleCurrentChange(val) {
      // this.currentPage = val
      this.getVideoLists(this.menu_id, val, this.limit)
    },
    // handleSizeChange(val) {
    //   this.limit = val
    //   this.getVideoLists(this.menu_id, 1, val)
    // },
    // 跳转详情页
    handleLink(item) {
      this.$router.push(
        `/${this.$route.params.site}/${this.$route.params.lang}/products/productDetail?product_id=${item.product_id}`,
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.videos {
  margin-top: 79px;
  border-top: 1px solid #ddd;
  .videos_detail {
    .videos_menu {
      display: flex;
      .videos_menu_item {
        background: #fff;
        width: 212px;
        height: 40px;
        border-radius: 152px;
        opacity: 1;
        box-sizing: border-box;
        border: 1px solid #000000;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        margin-right: 34px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          background: #000000;
          border-bottom: 2px solid #232931;
          color: #fff;
        }
      }
    }
    .videos_list {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0;
      .videos_list_item {
        width: calc((100% - 40px) / 3);
        box-sizing: border-box;
        min-height: 325px;
        border-radius: 15px;
        background: #f3f3f3;
        padding: 30px;
        margin-top: 30px;
        margin-right: 20px;
        .videos_video {
          position: relative;
          .videos_player {
            width: 100%;
            // height: 100%;
            height: 200px;
            margin-bottom: 20px;
            object-fit: cover;
          }
          .video-icon-box {
            cursor: pointer;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > .iconfont {
              font-size: 64px;
              color: #5f6266;
              transition: all 0.3s;
            }
          }
          &:hover {
            .video-icon-box {
              & > .iconfont {
                opacity: 0.7;
              }
            }
          }
        }
        .videos_content {
          cursor: pointer;
          .videos_text {
            font-size: 16px;
            line-height: 24px;
          }
          .title {
            font-size: 14px;
            line-height: 22px;
          }
          .model {
            font-size: 14px;
            line-height: 22px;
            color: #606266;
            margin-left: 10px;
          }
          &:hover {
            .title,
            .model {
              color: @theme;
            }
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  /deep/.el-pagination {
    padding: 20px;
    padding-bottom: 80px;
    text-align: center;
  }
  /deep/ .public-bread-crumb {
    padding: 16px 0 24px;
    .header-item {
      margin-left: 11px;
    }
  }
  /deep/ .public-title {
    .text-box {
      padding: 8px 0 50px 0;
      .title {
        font-size: 36px;
      }
    }
  }
  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 40px 0 20px 0;
        .title {
          font-size: 20px;
        }
      }
    }
    .videos_detail {
      .videos_menu {
        overflow-x: auto;
        height: 42px;
        align-items: center;
        .videos_menu_item {
          padding: 0 20px;
          height: 34px;
          line-height: 34px;
          font-size: 16px;
        }
      }
      .videos_list {
        margin-top: 0;
        .videos_list_item {
          width: 100%;
        }
      }
    }
  }
}
</style>
